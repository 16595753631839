import {Button, Pagination, PaginationItem} from '@mui/material';
import {
  CreateIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
} from 'assets/icons';
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from 'hooks';
import {
  useGetStationTypesListQuery,
  useGetStationsQuery,
} from 'store/slices/stations';

import Guard from '../../components/Guard/Guard';
import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IStationType} from 'store/models/stations';
import StationsTable from './StationsTable/StationsTable';
import StationsTopToolbar from './StationsTopToolbar/StationsTopToolbar';
import {UserRole} from '../../enums';
import {createButton} from 'styles/MUIStyles/buttons';
import {pagination} from 'styles/MUIStyles/common';
import styles from './Stations.module.scss';
import {useGetBranchesListQuery} from 'store/slices/branches';
import {useGetCompaniesListQuery} from 'store/slices/companies';
import {useNavigate} from 'react-router-dom';

const Stations: FC = () => {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useLocalStorage('stationsPageSize', 10);
  const [pageNumber, setPageNumber] = useLocalStorage('stationsPageNumber', 1);
  const [search, setSearch] = useLocalStorage('stationsSearch', '');
  const [status, setStatus] = useLocalStorage('stationsStatus', null);
  const [stationAttachedStatus, setStationAttachedStatus] = useLocalStorage(
    'stationAttachedStatus',
    null,
  );
  const debouncedSearch = useDebounce(search, 800);
  const [sortConfig, handleColumnClick] = useSortColumn('stationsSortConfig');
  const [
    isRequestWithDeletedStatusSuccess,
    setIsRequestWithDeletedStatusSuccess,
  ] = useState(false);
  const [
    isRequestWithNotAttachedStatusSuccess,
    setIsRequestWithNotAttachedStatusSuccess,
  ] = useState(false);

  const [companiesForStations, setCompaniesForStations] = useState<
    IGetCompaniesListItem[]
  >([]);
  const [branchesForStations, setBranchesForStations] = useState<
    IGetBranchesListItem[]
  >([]);
  const [stationTypes, setStationTypes] = useState<IStationType[]>([]);

  const {data: stationTypesData} = useGetStationTypesListQuery();
  const {data: companies} = useGetCompaniesListQuery({
    branchIds: branchesForStations,
  });
  const {data: branches} = useGetBranchesListQuery({
    companies: companiesForStations,
  });
  const {
    data: stationsData,
    originalArgs,
    isSuccess,
  } = useGetStationsQuery({
    pageSize,
    pageNumber,
    search: debouncedSearch,
    sortBy: sortConfig,
    status,
    stationAttachedStatus,
    companies: companiesForStations,
    branches: branchesForStations,
    stationTypes: stationTypes,
  });

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  useEffect(() => {
    const isDeletedStatusRequestSuccess =
      originalArgs &&
      originalArgs.status?.queryName === 'isDeleted' &&
      isSuccess;

    const isNotAttachedStatusRequestSuccess =
      originalArgs &&
      originalArgs.status?.queryName === 'isAttached' &&
      isSuccess;

    setIsRequestWithDeletedStatusSuccess(
      Boolean(isDeletedStatusRequestSuccess),
    );
    setIsRequestWithNotAttachedStatusSuccess(
      Boolean(isNotAttachedStatusRequestSuccess),
    );
  }, [originalArgs, isSuccess]);

  useUpdateEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    }
  }, [debouncedSearch, status, pageSize]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.stationsTop}>
        <h1 className={styles.stationsTitle}>Stations</h1>
        <Guard allowedRoles={[UserRole.Admin]}>
          <Button
            variant="contained"
            startIcon={<CreateIcon />}
            onClick={() => navigate('/stations/create')}
            sx={createButton}
          >
            Create
          </Button>
        </Guard>
      </div>
      <div className={styles.stationsMainContent}>
        <div className={styles.tableFiltersTop}>
          <StationsTopToolbar
            search={search}
            setSearch={setSearch}
            pageSize={pageSize}
            setPageSize={setPageSize}
            status={status}
            setStatus={setStatus}
            stationAttachedStatus={stationAttachedStatus}
            setStationAttachedStatus={setStationAttachedStatus}
            metaData={stationsData?.metadata || null}
            companies={companies || []}
            companiesForStations={companiesForStations}
            setCompaniesForStations={setCompaniesForStations}
            branches={branches?.data || []}
            branchesForStations={branchesForStations}
            setBranchesForStations={setBranchesForStations}
            stationTypesData={stationTypesData || []}
            stationTypes={stationTypes}
            setStationTypes={setStationTypes}
          />
        </div>
        <div style={{height: '75%', flexGrow: 1}}>
          <StationsTable
            stations={stationsData?.data || []}
            metaData={stationsData?.metadata || null}
            search={debouncedSearch}
            sortConfig={sortConfig}
            handleColumnClick={handleColumnClick}
            isRequestWithDeletedStatusSuccess={
              isRequestWithDeletedStatusSuccess
            }
            isRequestWithNotAttachedStatusSuccess={
              isRequestWithNotAttachedStatusSuccess
            }
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={stationsData?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Stations;
