import {
  ArrowAsc,
  ArrowDesc,
  CheckedCheckboxIcon,
  DefaultSortIcon,
  DownloadIcon,
  MarkIcon,
  MoreIcon,
  OpenInNewTab,
  ReplaceIcon,
  UnCheckCheckedCheckboxIcon,
  UncheckedCheckboxIcon,
  WarningIcon,
} from 'assets/icons';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  styled,
  tableCellClasses,
  tooltipClasses,
} from '@mui/material';
import React, {FC, memo, useState} from 'react';
import {TableComponents, TableVirtuoso} from 'react-virtuoso';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';

import Guard from 'components/Guard/Guard';
import {IGetMainBackupsListModified} from 'store/models/backups';
import {IMetadata} from 'store/models/common';
import {ISortConfig} from 'hooks/useSortColumn';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {UserRole} from 'enums';
import dayjs from 'dayjs';
import styles from '../Backups.module.scss';
import {useAppSelector} from 'store/store';
import {useGetNumberOfElements} from 'hooks';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);
const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    wordBreak: 'normal',
  },
});

interface Props {
  modifiedMainBackups: IGetMainBackupsListModified[];
  setModifiedMainBackups: (mainBackups: IGetMainBackupsListModified[]) => void;
  mainBackupSortConfig: ISortConfig;
  mainBackupHandleColumnClick: (column: string) => void;
  mainBackupsDebouncedSearch: string;
  metaData: IMetadata | null;
  downloadFileHandler: (backupIds: number[]) => void;
  openInNewTabHandler: (backupIds: number[]) => void;
  openAssignBackupModalHandler: (backupFile: any) => void;
  openMarkAsDeletedModalHandler: (backupFile: any) => void;
  setEditingFiles: (b: any) => void;
  editingFiles: any[];
}

const VirtuosoTableComponents: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} ref={ref} sx={{...tableContainer}} />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate'}} />,
  TableRow: ({item: _item, ...props}) => {
    return (
      <StyledTableRow
        {...props}
        key={_item.id}
        sx={{
          backgroundColor: _item.isChecked ? '#EBF2FF' : '#fff',
          '&:hover': {
            backgroundColor: _item.isChecked ? '#EBF2FF' : '#F5F8FF',
          },
        }}
      />
    );
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const columns: {
  label: string;
  dataKey: string;
}[] = [
  {label: 'checkbox', dataKey: 'checkbox'},
  {label: 'File name', dataKey: 'fileName'},
  {label: 'Directory', dataKey: 'directory'},
  {label: 'Size', dataKey: 'size'},
  {label: 'Type', dataKey: 'type'},
  {label: 'Created Date Time', dataKey: 'createdDateTime'},
  {label: 'Last modified', dataKey: 'editedDateTime'},
  {label: 'PosChassis Number', dataKey: 'posChassicNumber'},
  {label: 'Stations', dataKey: 'stationName'},
  {label: 'Task name', dataKey: 'commandName'},
];

const headerContent = (
  modifiedMainBackups: IGetMainBackupsListModified[],
  toggleAllCheckboxes: (isChecked: boolean) => void,
  getArrowForSortDirection: (column: string) => React.ReactNode,
  mainBackupHandleColumnClick: (column: string) => void,
  userRole: string | null,
) => {
  return (
    <TableRow>
      {columns.map((column) => {
        switch (column.label) {
          case 'checkbox': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '1%'}}>
                <Box sx={tableHeadCell}>
                  <span>
                    {modifiedMainBackups.length > 0 &&
                      (modifiedMainBackups.every(
                        (backup) => backup.isChecked,
                      ) ? (
                        <CheckedCheckboxIcon
                          onClick={() => toggleAllCheckboxes(false)}
                        />
                      ) : modifiedMainBackups.some(
                          (backup) => backup.isChecked,
                        ) ? (
                        <UnCheckCheckedCheckboxIcon
                          onClick={() => toggleAllCheckboxes(false)}
                        />
                      ) : (
                        <UncheckedCheckboxIcon
                          onClick={() => toggleAllCheckboxes(true)}
                        />
                      ))}
                  </span>
                </Box>
              </StyledTableCell>
            );
          }
          case '№': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '4%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
            );
          }
          case 'File name': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '15%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => mainBackupHandleColumnClick('fileName')}
                >
                  <span>File name</span>
                  {getArrowForSortDirection('fileName')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Directory': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '11%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => mainBackupHandleColumnClick('directory')}
                >
                  <span>Directory</span>
                  {getArrowForSortDirection('directory')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Size': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '7%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => mainBackupHandleColumnClick('size')}
                >
                  <span>Size</span>
                  {getArrowForSortDirection('size')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Type': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '5%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => mainBackupHandleColumnClick('type')}
                >
                  <span>Type</span>
                  {getArrowForSortDirection('type')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Created Date Time': {
            return (
              <StyledTableCell
                key={column.dataKey}
                sx={{width: '15%'}}
                onClick={() => mainBackupHandleColumnClick('createdDateTime')}
              >
                <Box sx={tableHeadCell}>
                  <span>Created date time</span>
                  {getArrowForSortDirection('createdDateTime')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Last modified': {
            return (
              <StyledTableCell
                key={column.dataKey}
                sx={{width: '10%'}}
                onClick={() => mainBackupHandleColumnClick('editedDateTime')}
              >
                <Box sx={tableHeadCell}>
                  <span>Last modified</span>
                  {getArrowForSortDirection('editedDateTime')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'PosChassis Number': {
            return (
              <StyledTableCell
                key={column.dataKey}
                sx={{width: '17%'}}
                onClick={() => mainBackupHandleColumnClick('posChassicNumber')}
              >
                <Box sx={tableHeadCell}>
                  <span>PosChassis number</span>
                  {getArrowForSortDirection('posChassicNumber')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Stations': {
            return (
              <StyledTableCell
                key={column.dataKey}
                sx={{width: '8%'}}
                onClick={() => mainBackupHandleColumnClick('stationName')}
              >
                <Box sx={tableHeadCell}>
                  <span>Stations</span>
                  {getArrowForSortDirection('stationName')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Task name': {
            return (
              <StyledTableCell
                key={column.dataKey}
                sx={{width: '17%'}}
                onClick={() => mainBackupHandleColumnClick('commandName')}
              >
                <Box sx={tableHeadCell}>
                  <span>Task name</span>
                  {getArrowForSortDirection('commandName')}
                </Box>
              </StyledTableCell>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </TableRow>
  );
};

const rowContent = (
  index: number,
  backup: IGetMainBackupsListModified,
  itemsOnPage: number[],
  toggleCheckbox: (backupId: number) => void,
  handleMoreDotsClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  setClickedBackup: (clickedBackup: IGetMainBackupsListModified | null) => void,
  isMenuOpen: boolean,
) => {
  return (
    <React.Fragment>
      {columns.map((column) => {
        switch (column.label) {
          case 'checkbox': {
            return (
              <StyledTableCell key={column.dataKey}>
                <Checkbox
                  style={{padding: 0}}
                  name="mainBackupCheckbox"
                  checked={backup.isChecked}
                  onChange={() => toggleCheckbox(backup.id)}
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                />
              </StyledTableCell>
            );
          }
          case '№': {
            return (
              <StyledTableCell key={column.dataKey}>
                {itemsOnPage.length ? itemsOnPage[index] : null}
              </StyledTableCell>
            );
          }
          case 'File name': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.fileName}{' '}
                {backup.errorMessage && (
                  <Tooltip title={backup.errorMessage} placement="top" arrow>
                    <WarningIcon
                      style={{
                        filter:
                          'invert(22%) sepia(27%) saturate(6553%) hue-rotate(345deg) brightness(132%) contrast(95%)',
                      }}
                    />
                  </Tooltip>
                )}
                {backup.deletedDateTime && (
                  <Tooltip
                    title={`Deleted at ${dayjs(backup.deletedDateTime).format(
                      'DD.MM.YYYY HH:mm',
                    )}`}
                    placement="top"
                    arrow
                  >
                    <MarkIcon
                      style={{
                        filter:
                          'invert(22%) sepia(27%) saturate(6553%) hue-rotate(345deg) brightness(132%) contrast(95%)',
                      }}
                    />
                  </Tooltip>
                )}
              </StyledTableCell>
            );
          }
          case 'Directory': {
            return (
              <StyledTableCell key={column.dataKey}>
                <Box sx={{display: 'inline-block'}}>
                  <CustomWidthTooltip title={backup.directory} arrow>
                    <span className={styles.mainBackupDirectory}>
                      {backup.directory}
                    </span>
                  </CustomWidthTooltip>
                </Box>
              </StyledTableCell>
            );
          }
          case 'Size': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.convertedSize}
              </StyledTableCell>
            );
          }
          case 'Type': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.type}
              </StyledTableCell>
            );
          }
          case 'Created Date Time': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.createdDateTime
                  ? dayjs(backup.createdDateTime).format('DD.MM.YYYY HH:mm')
                  : null}
              </StyledTableCell>
            );
          }
          case 'Last modified': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.editedDateTime
                  ? dayjs(backup.editedDateTime).format('DD.MM.YYYY HH:mm')
                  : null}
              </StyledTableCell>
            );
          }
          case 'PosChassis Number': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.posChassicNumber}
              </StyledTableCell>
            );
          }
          case 'Stations': {
            return (
              <StyledTableCell key={column.dataKey}>
                {backup.stationName}
              </StyledTableCell>
            );
          }
          case 'Task name': {
            return (
              <StyledTableCell key={column.dataKey}>
                <Box sx={{display: 'inline-block'}}>
                  <CustomWidthTooltip title={backup.commandName} arrow>
                    <span className={styles.allBackupDirectory}>
                      {backup.commandName}
                    </span>
                  </CustomWidthTooltip>
                </Box>
              </StyledTableCell>
            );
          }
          default: {
            return (
              <StyledTableCell key={column.dataKey}>
                <Button
                  id="basic-button"
                  aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? 'true' : undefined}
                  onClick={(event) => {
                    handleMoreDotsClick(event);
                    setClickedBackup(backup);
                  }}
                >
                  <MoreIcon />
                </Button>
              </StyledTableCell>
            );
          }
        }
      })}
    </React.Fragment>
  );
};

const EditFilesTable: FC<Props> = memo((props) => {
  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const userRole = useAppSelector((state) => state.auth.role);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clickedBackup, setClickedBackup] =
    useState<IGetMainBackupsListModified | null>(null);
  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreDotsClose = () => setAnchorEl(null);
  const isMenuOpen = Boolean(anchorEl);

  const getArrowForSortDirection = (column: string) => {
    if (props.mainBackupSortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.mainBackupSortConfig.direction === 'asc' ? (
      <ArrowAsc />
    ) : (
      <ArrowDesc />
    );
  };

  const toggleCheckbox = (backupId: number) => {
    props.setEditingFiles(
      props.editingFiles.filter((item) => item.id !== backupId) || [],
    );
    props.setModifiedMainBackups(
      props?.modifiedMainBackups.map((backup) => {
        if (backup.id === backupId) {
          return {...backup, isChecked: !backup.isChecked};
        }
        return backup;
      }) || [],
    );
  };

  const toggleAllCheckboxes = (isChecked: boolean) => {
    const res =
      props.modifiedMainBackups.map((backup) => ({
        ...backup,
        isChecked,
      })) || [];
    const filteredFiles =
      res?.filter(
        (item) =>
          item.isChecked &&
          !props.editingFiles.some((item2: any) => item2.id === item.id),
      ) || [];
    if (!isChecked) {
      const uniqueArray = [
        ...filteredFiles,
        ...props.editingFiles.filter((item) =>
          res.some((item2) => item.id === item2.id && item2.isChecked),
        ),
      ].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );

      props.setEditingFiles([...uniqueArray]);
      props.setModifiedMainBackups(res);
    } else {
      const uniqueArray = [
        ...filteredFiles,
        ...props.editingFiles.filter(
          (item) =>
            !res.some((item2) => item.id === item2.id && item2.isChecked),
        ),
      ].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );

      props.setEditingFiles([...uniqueArray]);
      props.setModifiedMainBackups(res);
    }
  };

  return (
    <>
      <TableVirtuoso
        data={props.editingFiles}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          headerContent(
            props.editingFiles,
            toggleAllCheckboxes,
            getArrowForSortDirection,
            props.mainBackupHandleColumnClick,
            userRole,
          )
        }
        itemContent={(index, backup) =>
          rowContent(
            index,
            backup,
            itemsOnPage,
            toggleCheckbox,
            handleMoreDotsClick,
            setClickedBackup,
            isMenuOpen,
          )
        }
      />

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {props.editingFiles.filter(
          (modifiedMainBackup) => modifiedMainBackup.isChecked,
        ).length > 1 ? null : (
          <MenuItem
            sx={{
              ...tableMoreDotsMenu,
              '& a': {
                textDecoration: 'none',
                color: 'inherit',
              },
            }}
            onClick={() => {
              handleMoreDotsClose();
              if (clickedBackup) {
                props.openInNewTabHandler([clickedBackup.id]);
              }
            }}
          >
            <OpenInNewTab />
            <span>{clickedBackup?.fileLink ? 'Open in new tab' : null}</span>
          </MenuItem>
        )}
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            handleMoreDotsClose();
            if (clickedBackup) {
              props.downloadFileHandler([clickedBackup.id]);
            }
          }}
        >
          <DownloadIcon />
          <span>Download</span>
        </MenuItem>
        <Guard allowedRoles={[UserRole.Admin]}>
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              if (clickedBackup) {
                props.openAssignBackupModalHandler(clickedBackup);
              }
            }}
          >
            <ReplaceIcon />
            <span>Assign to another station</span>
          </MenuItem>
        </Guard>

        <Guard allowedRoles={[UserRole.Admin]}>
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              if (clickedBackup) {
                props.openMarkAsDeletedModalHandler(clickedBackup);
              }
            }}
          >
            <MarkIcon />
            <span>
              {clickedBackup?.deletedDateTime ? 'Recover' : 'Mark as deleted'}
            </span>
          </MenuItem>
        </Guard>
      </Menu>
    </>
  );
});

export default EditFilesTable;
