import {AxiosError, AxiosRequestConfig} from 'axios';

import {BaseQueryFn} from '@reduxjs/toolkit/dist/query/react';
import History from 'core/history';
import {LOGIN_PAGE_PATH} from 'router/constants';
import axios from 'axios';
import {setIsAuth} from 'store/slices/authSlice';
import store from 'store/store';

const API_URL = process.env.REACT_APP_SERVER_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'accessToken',
    )}`;
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error: any) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axiosApi.post('/auth/rtoken', {
          accessToken,
          refreshToken,
        });
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return axiosApi.request(originalRequest);
      } catch (error: any) {
        store.dispatch(setIsAuth(false));
        localStorage.clear();
        History.push(LOGIN_PAGE_PATH);
      }
    }
    return Promise.reject(error);
  },
);

export const axiosBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      responseType?: AxiosRequestConfig['responseType'];
      transformResponse?: AxiosRequestConfig['transformResponse'];
    },
    unknown,
    {status?: number; data: any}
  > =>
  async ({
    url,
    method,
    data,
    params,
    headers,
    responseType,
    transformResponse,
  }) => {
    try {
      const result = await axiosApi({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
        responseType: responseType || 'json', // По умолчанию JSON
        transformResponse,
      });

      // Если тип ответа — blob, возвращаем весь результат
      return {data: responseType === 'blob' ? result : result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosApi;
