import {Box, Pagination, PaginationItem, Tab, Tabs} from '@mui/material';
import {PaginationArrowLeft, PaginationArrowRight} from '../../assets/icons';
import React, {ChangeEvent, FC, useState} from 'react';
import {pagination, tab} from 'styles/MUIStyles/common';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from '../../hooks';
import {
  useGetSystemLogsQuery,
  useGetUpdaterAlertsQuery,
} from '../../store/slices/logs';

import Alerts from './Alerts/Alerts';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import LogsDeleteButton from './LogsDeleteButton/LogsDeleteButton';
import {StationsListItem} from 'store/models/stations';
import SystemLogs from './SystemLogs/SystemLogs';
import {a11yProps} from 'core/utils';
import dayjs from 'dayjs';
import styles from './Logs.module.scss';
import {useGetBranchesListQuery} from 'store/slices/branches';
import {useGetCompaniesListQuery} from 'store/slices/companies';
import {useGetStationsListQuery} from 'store/slices/stations';

const Logs: FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  //systemSettings
  const [systemLogsPageSize, setSystemLogsPageSize] = useLocalStorage(
    'systemLogsPageSize',
    10,
  );
  const [systemLogsPageNumber, setSystemLogsPageNumber] = useLocalStorage(
    'systemLogsPageNumber',
    1,
  );
  const [systemLogsSearch, setSystemLogsSearch] = useLocalStorage(
    'systemLogsSearch',
    '',
  );

  const [systemLogsStatus, setSystemLogsStatus] = useLocalStorage(
    'systemSettingsStatus',
    null,
  );
  const [systemLogsStartTime, setSystemLogsStartTime] = useLocalStorage(
    'systemLogsStartTime',
    dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).utc().format(),
  );
  const [systemLogsEndTime, setSystemLogsEndTime] = useLocalStorage(
    'systemLogsEndTime',
    dayjs().set('hour', 23).set('minute', 59).utc().format(),
  );
  const systemLogsDebouncedSearch = useDebounce(systemLogsSearch, 800);
  const [systemLogsSortConfig, handleSystemLogsColumnClick] = useSortColumn(
    'systemLogsSortConfig',
    {column: 'createdTimeUTC', direction: 'desc'},
  );
  //systemSettings

  //alerts
  const [alertsPageSize, setAlertsPageSize] = useLocalStorage(
    'alertsPageSize',
    10,
  );
  const [alertsPageNumber, setAlertsPageNumber] = useLocalStorage(
    'alertsPageNumber',
    1,
  );
  const [alertsMessageSearch, setAlertsMessageSearch] = useLocalStorage(
    'alertsMessageSearch',
    '',
  );
  const [alertsPosChassisNumberSearch, setAlertsPosChassisNumberSearch] =
    useLocalStorage('alertsPosChassisNumberSearch', '');
  const [alertsFiscalDeviceIdSearch, setAlertsFiscalDeviceIdSearch] =
    useLocalStorage('alertsFiscalDeviceIdSearch', '');
  const [alertsStartTime, setAlertsStartTime] = useLocalStorage(
    'alertsStartTime',
    dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).utc().format(),
  );
  const [alertsEndTime, setAlertsEndTime] = useLocalStorage(
    'alertsEndTime',
    dayjs().set('hour', 23).set('minute', 59).utc().format(),
  );
  const alertsDebouncedMessageSearch = useDebounce(alertsMessageSearch, 800);
  const alertsPosChassisNumberDebouncedSearch = useDebounce(
    alertsPosChassisNumberSearch,
    800,
  );
  const alertsFiscalDeviceIdDebouncedSearch = useDebounce(
    alertsFiscalDeviceIdSearch,
    800,
  );

  const [alertsSortConfig, handleAlertsColumnClick] = useSortColumn(
    'alertsSortConfig',
    {
      column: 'createdDateTime',
      direction: 'desc',
    },
  );

  const [companiesForAlerts, setCompaniesForAlerts] = useState<
    IGetCompaniesListItem[]
  >([]);
  const [branchesForAlerts, setBranchesForAlerts] = useState<
    IGetBranchesListItem[]
  >([]);
  const [stationsForAlerts, setStationsForAlerts] = useState<
    StationsListItem[]
  >([]);

  //alerts

  const {data: companies} = useGetCompaniesListQuery({
    branchIds: branchesForAlerts,
    stationIds: stationsForAlerts,
  });
  const {data: branches} = useGetBranchesListQuery({
    companies: companiesForAlerts,
    stationIds: stationsForAlerts,
  });
  const {data: stations} = useGetStationsListQuery({
    branches: branchesForAlerts,
    companies: companiesForAlerts,
  });

  const {data: logsData} = useGetSystemLogsQuery({
    pageSize: systemLogsPageSize,
    pageNumber: systemLogsPageNumber,
    status: systemLogsStatus,
    startTime: systemLogsStartTime,
    endTime: systemLogsEndTime,
    sortBy: systemLogsSortConfig,
    search: systemLogsDebouncedSearch,
  });

  const {data: updaterAlertsData} = useGetUpdaterAlertsQuery({
    pageSize: alertsPageSize,
    pageNumber: alertsPageNumber,
    sortBy: alertsSortConfig,
    startTime: alertsStartTime,
    endTime: alertsEndTime,
    search: alertsDebouncedMessageSearch,
    posChassisNumberSearch: alertsPosChassisNumberDebouncedSearch,
    fiscalDeviceIdSearch: alertsFiscalDeviceIdDebouncedSearch,
    companies: companiesForAlerts,
    branches: branchesForAlerts,
    stations: stationsForAlerts,
  });

  const handleOnSystemLogsPageChange = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    setSystemLogsPageNumber(page);
  };

  const handleOnAlertsPageChange = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    setAlertsPageNumber(page);
  };

  useUpdateEffect(() => {
    if (systemLogsPageNumber > 1) {
      setSystemLogsPageNumber(1);
    }
  }, [
    systemLogsDebouncedSearch,
    systemLogsPageSize,
    systemLogsStatus,
    systemLogsStartTime,
    systemLogsEndTime,
  ]);

  useUpdateEffect(() => {
    if (alertsPageNumber > 1) {
      setAlertsPageNumber(1);
    }
  }, [
    alertsDebouncedMessageSearch,
    alertsFiscalDeviceIdDebouncedSearch,
    alertsPosChassisNumberDebouncedSearch,
    alertsPageSize,
    alertsStartTime,
    alertsEndTime,
  ]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.logsTop}>
        <h1 className={styles.logsTitle}>Logs</h1>
        {activeTab === 0 && (
          <LogsDeleteButton
            systemLogsStartTime={systemLogsStartTime}
            systemLogsEndTime={systemLogsEndTime}
          />
        )}
      </div>
      <div className={styles.logsMainContent}>
        <Box sx={{height: '100%'}}>
          <Tabs
            TabIndicatorProps={{
              style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
            }}
            value={activeTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab sx={tab} label="System Logs" {...a11yProps(0)} />
            <Tab sx={tab} label="Alerts" {...a11yProps(1)} />
          </Tabs>
          <div style={{height: '93%'}}>
            <CustomTabPanel value={activeTab} index={0}>
              <SystemLogs
                logsData={logsData}
                search={systemLogsSearch}
                debouncedSearch={systemLogsDebouncedSearch}
                setSearch={setSystemLogsSearch}
                pageSize={systemLogsPageSize}
                setPageSize={setSystemLogsPageSize}
                status={systemLogsStatus}
                setStatus={setSystemLogsStatus}
                logsStartTime={systemLogsStartTime}
                setLogsStartTime={setSystemLogsStartTime}
                logsEndTime={systemLogsEndTime}
                setLogsEndTime={setSystemLogsEndTime}
                sortConfig={systemLogsSortConfig}
                handleColumnClick={handleSystemLogsColumnClick}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <Alerts
                updaterAlertsData={updaterAlertsData?.data || []}
                posChassisNumber={alertsPosChassisNumberSearch}
                setPosChassisNumber={setAlertsPosChassisNumberSearch}
                fiscalDeviceId={alertsFiscalDeviceIdSearch}
                setFiscalDeviceId={setAlertsFiscalDeviceIdSearch}
                alertsMessage={alertsMessageSearch}
                setAlertsMessage={setAlertsMessageSearch}
                pageSize={alertsPageSize}
                setPageSize={setAlertsPageSize}
                alertsStartTime={alertsStartTime}
                setAlertsStartTime={setAlertsStartTime}
                alertsEndTime={alertsEndTime}
                setAlertsEndTime={setAlertsEndTime}
                sortConfig={alertsSortConfig}
                handleColumnClick={handleAlertsColumnClick}
                metaData={updaterAlertsData?.metadata || null}
                companies={companies || []}
                companiesForAlerts={companiesForAlerts}
                setCompaniesForAlerts={setCompaniesForAlerts}
                branches={branches?.data || []}
                branchesForAlerts={branchesForAlerts || []}
                setBranchesForAlerts={setBranchesForAlerts}
                stations={stations || []}
                stationsForAlerts={stationsForAlerts}
                setStationsForAlerts={setStationsForAlerts}
              />
            </CustomTabPanel>
          </div>
        </Box>
      </div>

      <div className={styles.pagination}>
        <Pagination
          count={
            activeTab === 0
              ? logsData?.metadata?.pageCount || 1
              : updaterAlertsData?.metadata?.pageCount || 1
          }
          page={activeTab === 0 ? systemLogsPageNumber : alertsPageNumber}
          onChange={
            activeTab === 0
              ? handleOnSystemLogsPageChange
              : handleOnAlertsPageChange
          }
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Logs;
