import {
  ICreateCompanyArgs,
  ICreateCompanyServerError,
  IEditCompanyArgs,
  IGetCompaniesArgs,
  IGetCompaniesListItem,
  IGetCompaniesResponse,
  IGetCompanyById,
} from '../models/companies';

import {IGetBranchesListItem} from 'store/models/branches';
import {api} from './api';

export const companies = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCompaniesList: builder.query<
        IGetCompaniesListItem[],
        {stationIds?: any[]; branchIds?: IGetBranchesListItem[]}
      >({
        query: (args) => {
          const params = new URLSearchParams();

          if (args.stationIds && args.stationIds.length) {
            args.stationIds.forEach((station) => {
              params.append('stationIds', station.id);
            });
          }

          if (args.branchIds && args.branchIds.length) {
            args.branchIds.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }

          return {
            url: 'companies/dictionary',
            method: 'get',
            params: params,
          };
        },
        providesTags: () => ['Companies', 'Dictionarys'],
      }),
      getCompanies: builder.query<IGetCompaniesResponse, IGetCompaniesArgs>({
        query: (companiesParams) => {
          const params = new URLSearchParams();

          if (companiesParams.search) {
            params.append('term', companiesParams.search);
          }

          if (companiesParams.pageNumber) {
            params.append('pageNumber', String(companiesParams.pageNumber));
          }

          if (companiesParams.pageSize) {
            params.append('pageSize', String(companiesParams.pageSize));
          }

          if (
            companiesParams.sortBy?.column &&
            companiesParams.sortBy.direction
          ) {
            const column = companiesParams.sortBy?.column;
            const direction = companiesParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `companies?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Companies'],
      }),
      createCompany: builder.mutation<
        ICreateCompanyServerError,
        ICreateCompanyArgs
      >({
        query: (companyData) => ({
          url: 'companies',
          method: 'post',
          data: companyData,
        }),
        invalidatesTags: ['Companies'],
      }),
      getCompanyById: builder.query<IGetCompanyById, string>({
        query: (companyId) => ({
          url: `companies/${companyId}`,
          method: 'get',
        }),
      }),
      editCompany: builder.mutation<string, IEditCompanyArgs>({
        query: (companyData) => ({
          url: 'companies',
          method: 'put',
          data: companyData,
        }),
        invalidatesTags: ['Companies', 'Branches'],
      }),
      deleteCompany: builder.mutation<string, string>({
        query: (companyId) => ({
          url: 'companies',
          method: 'delete',
          data: {id: companyId},
        }),
        invalidatesTags: ['Companies'],
      }),
    };
  },
});

export const {
  useGetCompaniesListQuery,
  useLazyGetCompaniesListQuery,
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useLazyGetCompanyByIdQuery,
  useEditCompanyMutation,
  useDeleteCompanyMutation,
} = companies;
