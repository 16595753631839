import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';

import {api} from './slices/api';
import authReducer from 'store/slices/authSlice';
import {configureStore} from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    auth: authReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['api.queries.getReportByStationIdFile'], // Игнорировать сериализацию для этого пути
        ignoredActions: ['api/queries/getReportByStationIdFile/fulfilled'], // Игнорировать для действия
      },
    }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
