import {Alert, Box, Snackbar} from '@mui/material';
import {CloseIcon, ErrorNotification} from 'assets/icons';
import {Link, Navigate} from 'react-router-dom';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
} from 'styles/MUIStyles/snackbars';
import {
  useLazyGetProfileDataQuery,
  useSendOTPCodeMutation,
} from 'store/slices/profile';
import {useRef, useState} from 'react';

import {ISnackbarState} from 'store/models/common';
import {LOGIN_PAGE_PATH} from 'router/constants';
import OtpInput from 'components/OtpInput/OtpInput';
import {mainPagesByRole} from 'core/constants';
import styles from './OTPCode.module.scss';
import {useAppSelector} from 'store/store';

const OTPCode = () => {
  const email = useAppSelector((state) => state.auth.email);
  const role = useAppSelector((state) => state.auth.role);
  const isAuth = useAppSelector((state) => state.auth.isAuth);

  const [sendCode, {isLoading}] = useSendOTPCodeMutation();
  const [getProfileData] = useLazyGetProfileDataQuery();

  const [errorSnackbar, setErrorSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: errorOpen,
  } = errorSnackbar;

  const handleSnackbarClose = () => {
    setErrorSnackbar({...errorSnackbar, open: false});
    setErrorMessage(null);
  };

  const lastRequestTime = useRef(0);

  const handleSubmit = async (code: number) => {
    const now = Date.now();
    
    if (now - lastRequestTime.current < 3000) {
      setErrorMessage("Please wait before resubmitting.");
      setErrorSnackbar({ vertical: 'top', horizontal: 'center', open: true });
      return;
    }
  
    lastRequestTime.current = now; // Обновляем время последнего запроса
  
    const res = await sendCode({ email, code });
  
    if ('error' in res && 'status' in res.error) {
      if (res.error?.status === 400 || res.error?.status === 404) {
        const errors = res.error.data?.errors;
        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            //@ts-ignore
            setErrorMessage(errors[key]);
            setErrorSnackbar({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            return;
          }
        }
      }
    }
  
    getProfileData();
  };

  if (!email) {
    return <Navigate to={'/' + LOGIN_PAGE_PATH} replace={true} />;
  }

  if (isAuth && role) {
    return <Navigate to={'/' + mainPagesByRole[role]} replace={true} />;
  }

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.title}>Two Factor Authentication</h1>
      <p className={styles.text}>
        Please, enter the authentication code. <br />
        Code has been sent to email: {email}
      </p>
      <div className="container">
        <OtpInput onSubmit={handleSubmit} isLoading={isLoading} />
      </div>
      <p className={styles.text}>Code is valid for 5 minutes.</p>
      <p className={styles.text}>
        Didn't receive code? Go <Link to={'/' + LOGIN_PAGE_PATH}>back</Link> and
        try again
      </p>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={errorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};
export default OTPCode;
