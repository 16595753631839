import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Snackbar,
  SnackbarOrigin,
  TextField,
} from '@mui/material';
import {CloseIcon, SelectPopupIcon, SuccessNotification} from 'assets/icons';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import React, {FC, useState} from 'react';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {searchAndSelect, searchAndSelectError} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';

import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {ErrorMessage} from '@hookform/error-message';
import {ICompanyOwnersList as ICompanyOwner} from 'store/models/companyOwners';
import {modal} from 'styles/MUIStyles/modal';
import styles from './CompanyOwnersAddCompany.module.scss';
import {useAddCompanyToCompanyOwnerMutation} from '../../../store/slices/companyOwners';
import {useLazyGetCompaniesListQuery} from '../../../store/slices/companies';
import {useUpdateEffect} from 'hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clickedCompanyOwner: ICompanyOwner | null;
}

interface IFormData {
  id: string;
  name: string;
  address: string | null;
  registrationNumber: string | null;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const CompanyOwnersAddCompany: FC<Props> = (props) => {
  const {
    handleSubmit,
    clearErrors,
    reset,
    control,
    setError,
    formState: {errors},
  } = useForm<{
    company: IFormData;
  }>({
    defaultValues: {
      company: {
        id: '',
        name: '',
        address: null,
        registrationNumber: null,
      },
    },
  });

  const [
    getCompaniesList,
    {data: companies, isFetching: isGetCompaniesListFetching},
  ] = useLazyGetCompaniesListQuery();

  const [
    addCompanyToCompanyOwner,
    {
      isSuccess: isAddingCompanyToCompanyOwnerSuccess,
      isLoading: isAddingCompanyToCompanyOwnerLoading,
      error: addCompanyToCompanyOwnerError,
    },
  ] = useAddCompanyToCompanyOwnerMutation();

  const [companyName, setCompanyName] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = snackbar;

  const onSubmit: SubmitHandler<{
    company: IFormData;
  }> = async (formData) => {
    if (props.clickedCompanyOwner) {
      const response = addCompanyToCompanyOwner({
        ownerId: props.clickedCompanyOwner.id,
        companyId: formData.company.id,
      });

      if (response) {
        if (!('error' in response)) {
          setCompanyName(formData.company.name);
        }
      }
    }
  };

  const clearDataAndErrors = () => {
    reset();
    clearErrors();
    props.onClose();
  };

  const handleSnackbarClose = () => setSnackbar({...snackbar, open: false});

  useUpdateEffect(() => {
    if (isAddingCompanyToCompanyOwnerSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      reset();
      props.onClose();
    }
  }, [isAddingCompanyToCompanyOwnerSuccess]);

  useUpdateEffect(() => {
    if (props.isOpen) {
      getCompaniesList({});
    }
  }, [props.isOpen]);

  useUpdateEffect(() => {
    if (addCompanyToCompanyOwnerError) {
      if ('data' in addCompanyToCompanyOwnerError) {
        const errors = addCompanyToCompanyOwnerError.data?.errors;
        if (errors?.companyId?.[0] || errors?.CompanyId?.[0]) {
          setError('company', {
            type: 'custom',
            message: errors?.companyId?.[0] || errors?.CompanyId?.[0],
          });
        }

        if (errors?.ownerId?.[0] || errors?.OwnerId?.[0]) {
          setError('company', {
            type: 'custom',
            message: errors?.ownerId?.[0] || errors?.OwnerId?.[0],
          });
        }
      }
    }
  }, [addCompanyToCompanyOwnerError, setError]);

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={clearDataAndErrors}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...modal, width: '35%'}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.modalTop}>
              <h2 className={styles.companyOwnersAddCompanyTitle}>
                Add company
              </h2>
              <CloseIcon onClick={clearDataAndErrors} />
            </div>
            <Controller
              name={'company'}
              control={control}
              rules={{
                validate: (value) => {
                  if (!value?.name && !value?.id) {
                    return 'This field cannot be empty';
                  }
                },
              }}
              render={({field}) => (
                <Autocomplete
                  {...field}
                  options={companies || []}
                  loading={isGetCompaniesListFetching}
                  popupIcon={<SelectPopupIcon />}
                  PaperComponent={(props) => (
                    <Paper
                      sx={{
                        borderRadius: '12px',
                        boxShadow: '0px 4px 20px 0px #4444441F',
                        marginTop: '8px',
                        cursor: 'pointer',
                      }}
                      {...props}
                    />
                  )}
                  sx={{width: '100%'}}
                  ListboxProps={{
                    style: {maxHeight: '200px'},
                    className: 'autoCompleteListBox',
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value.name === '' ? true : option.id === value.id
                  }
                  getOptionLabel={(option) => (option?.name ? option.name : '')}
                  onChange={(_, value) => {
                    if (value) {
                      field.onChange(value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  renderOption={(props: any, option) => (
                    <span {...props} className={styles.dropDownItem}>
                      {option?.name}
                    </span>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select company name"
                      sx={
                        errors?.company
                          ? searchAndSelectError
                          : {...searchAndSelect, marginBottom: '16px'}
                      }
                    />
                  )}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={'company'}
              render={({message}) => (
                <span className={styles.inputErrorMessage}>{message}</span>
              )}
            />
            <Divider
              sx={{backgroundColor: '#E4E4EF', margin: '18px 0 14px 0'}}
            />
            <div className={styles.buttonsContainer}>
              <Button sx={cancelButton} onClick={clearDataAndErrors}>
                Cancel
              </Button>
              <ButtonWithProgress
                type="submit"
                sx={{...createButtonModal}}
                loading={isAddingCompanyToCompanyOwnerLoading}
                disabled={isAddingCompanyToCompanyOwnerLoading}
              >
                Add company
              </ButtonWithProgress>
            </div>
          </form>
        </Box>
      </Modal>
      {companyName && props.clickedCompanyOwner ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Company {`"${companyName}"`} successfully added to owner{' '}
              {`"${props.clickedCompanyOwner.email}"`}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default CompanyOwnersAddCompany;
