import {ArrowAsc, ArrowDesc, DefaultSortIcon} from 'assets/icons';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  styled,
  tableCellClasses,
  tooltipClasses,
} from '@mui/material';
import React, {FC, memo} from 'react';
import {TableComponents, TableVirtuoso} from 'react-virtuoso';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';

import {IGetLogsList} from '../../../../store/models/logs';
import {IMetadata} from 'store/models/common';
import {ISortConfig} from '../../../../hooks/useSortColumn';
import dayjs from 'dayjs';
import styles from '../../LogsTable.module.scss';
import timezone from 'dayjs/plugin/timezone';
import {useGetNumberOfElements} from 'hooks';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    wordBreak: 'normal',
  },
});

interface Props {
  logs: IGetLogsList[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
  search: string;
}

const VirtuosoTableComponents: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
      sx={tableContainer}
    />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate'}} />,
  TableRow: ({item: _item, ...props}) => {
    return <StyledTableRow {...props} key={_item.id} />;
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const columns = [
  {label: '№', dataKey: 'number'},
  {label: 'Message', dataKey: 'message'},
  {label: 'Created date time', dataKey: 'createdTimeUtc'},
  {label: 'Level', dataKey: 'level'},
];

const headerContent = (
  getArrowForSortDirection: (column: string) => React.ReactNode,
  handleColumnClick: (column: string) => void,
) => {
  return (
    <TableRow>
      {columns.map((column) => {
        switch (column.label) {
          case '№': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
            );
          }
          case 'Message': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '60%'}}>
                <Box sx={tableHeadCell}>
                  <span>Message</span>
                </Box>
              </StyledTableCell>
            );
          }
          case 'Created date time': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '20%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => handleColumnClick('createdTimeUTC')}
                >
                  <span>Created date time</span>
                  {getArrowForSortDirection('createdTimeUTC')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Level': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '15%'}}>
                <Box sx={tableHeadCell}>
                  <span>Level</span>
                </Box>
              </StyledTableCell>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </TableRow>
  );
};

const rowContent = (
  index: number,
  log: IGetLogsList,
  itemsOnPage: number[],
) => {
  return (
    <React.Fragment>
      {columns.map((column) => {
        switch (column.label) {
          case '№': {
            return (
              <StyledTableCell key={column.dataKey}>
                {itemsOnPage.length ? itemsOnPage[index] : null}
              </StyledTableCell>
            );
          }
          case 'Message': {
            return (
              <StyledTableCell key={column.dataKey}>
                <Box sx={{display: 'inline-block'}}>
                  <CustomWidthTooltip title={log.message} arrow>
                    <span className={styles.logsMessage}>{log.message}</span>
                  </CustomWidthTooltip>
                </Box>
              </StyledTableCell>
            );
          }
          case 'Created date time': {
            return (
              <StyledTableCell key={column.dataKey}>
                {log.createdTimeUtc
                  ? dayjs
                      .utc(log.createdTimeUtc)
                      .tz(dayjs.tz.guess())
                      .format('DD.MM.YYYY HH:mm')
                  : null}
              </StyledTableCell>
            );
          }
          case 'Level': {
            return (
              <StyledTableCell key={column.dataKey}>
                <span
                  className={`${styles.logsLevel} ${
                    styles[log.level.toLowerCase()]
                  }`}
                >
                  {log.level}
                </span>
              </StyledTableCell>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </React.Fragment>
  );
};

const SystemLogsTable: FC<Props> = memo((props) => {
  const itemsOnPage = useGetNumberOfElements(props.metaData);

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  return (
    <div style={{height: '100%'}}>
      <TableVirtuoso
        data={props.logs}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          headerContent(getArrowForSortDirection, props.handleColumnClick)
        }
        itemContent={(index, log) => rowContent(index, log, itemsOnPage)}
      />
    </div>
  );
});

export default SystemLogsTable;
