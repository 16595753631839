import {Box, Paper, Typography} from '@mui/material';
import {FC, MutableRefObject, useState} from 'react';
import {read, utils} from 'xlsx';

import {useUpdateEffect} from 'hooks';

interface ReportsTableProps {
  reportsData: string;
  tableRef: MutableRefObject<HTMLDivElement>;
  isFetching: boolean;
  isError: boolean;
  type: string;
  reportStations: string;
}

const ReportsTable: FC<ReportsTableProps> = ({
  reportsData,
  tableRef,
  isFetching,
  isError,
  type,
  reportStations,
}) => {
  const [__html, setHtml] = useState('');
  const [firstRows, setFirstRows] = useState<string[][]>([]);

  useUpdateEffect(() => {
    if (reportsData) {
      const wb = read(reportsData);
      const ws = wb.Sheets[wb.SheetNames[0]];

      // Extract data as rows, where each row is an array of cell values
      const rows = utils.sheet_to_json(ws, {header: 1}) as string[][];

      // Extract first 6 rows for display outside the table
      const firstSixRows = rows.slice(0, 6);
      setFirstRows(firstSixRows);

      // Convert the entire sheet to HTML for the full table
      const data = utils.sheet_to_html(ws);
      setHtml(data);
    } else {
      setHtml('');
      setFirstRows([]);
    }
  }, [reportsData, isFetching, isError]);

  return (
    <>
      {reportsData &&
        firstRows.length > 0 &&
        !isFetching &&
        type === 'EKJ_REPORT' &&
        !!reportStations.length && (
          <Box mb={3}>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
              {firstRows.map((row, index) => (
                <Paper
                  key={index}
                  elevation={1}
                  sx={{
                    padding: 2,
                    marginBottom: 1,
                    borderRadius: 2,
                    backgroundColor: 'background.paper',
                    boxShadow: 'none',
                    width: index === 0 ? '100%' : 'auto',
                  }}
                >
                  <Typography variant="h5" sx={{color: 'text.secondary'}}>
                    {row.map((cell, cellIndex) => {
                      if (cellIndex === index) {
                        return (
                          <Box
                            key={cellIndex}
                            sx={{
                              marginBottom: 1,
                              fontSize: '35px',
                              width: '100%',
                            }}
                          >
                            {cell}
                          </Box>
                        );
                      }

                      return (
                        <Box key={cellIndex} sx={{marginBottom: 1}}>
                          {cell}
                        </Box>
                      );
                    })}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </Box>
        )}

      {reportsData && __html && !isFetching ? (
        <div style={{overflowY: 'hidden'}}>
          <div
            style={{
              margin:
                type === 'EKJ_REPORT' && reportStations.length
                  ? '-370px 0 0 0'
                  : '',
            }}
            ref={tableRef}
            dangerouslySetInnerHTML={{__html}}
          />
        </div>
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <p>
            Select report type, station, and other filters to retrieve data.
          </p>
        </Box>
      )}
    </>
  );
};

export default ReportsTable;
