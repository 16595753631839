import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import {
  CalendarIcon,
  CheckedCheckboxIcon,
  CloseIcon,
  FilterIcon,
  SelectPopupIcon,
  UncheckedCheckboxIcon,
} from 'assets/icons';
import React, {FC} from 'react';
import {
  calendarStyles,
  chipStyles,
  searchAndSelect,
  selectRowsPaperProps,
} from 'styles/MUIStyles/common';

import {DateTimePicker} from '@mui/x-date-pickers';
import {IGetBackupFileTypes} from '../../../../store/models/enums';
import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IMetadata} from 'store/models/common';
import { ListboxComponent } from 'views/Reports/ReportsTopToolbar/ListBoxComponent';
import MenuItem from '@mui/material/MenuItem';
import {StationsListItem} from 'store/models/stations';
import {clearFiltersButton} from '../../../../styles/MUIStyles/buttons';
import dayjs from 'dayjs';
import {pageSizes} from 'core/constants';
import search from 'assets/icons/search.svg';
import styles from '../../Backups.module.scss';

interface Props {
  search: string; 
  setSearch: (search: string) => void;
  allBackupsStartDate: string | null;
  setAllBackupsStartDate: (date: string | null) => void;
  allBackupsFinishDate: string | null;
  setAllBackupsFinishDate: (data: string | null) => void;
  allBackupsPageSize: number;
  setAllBackupsPageSize: (pageSize: number) => void;
  stationsForAllBackups: StationsListItem[];
  selectedStationsForAllBackups: StationsListItem[];
  setSelectedStationsForAllBackups: (stations: StationsListItem[]) => void;
  metaData: IMetadata | null;
  backupsFileTypes: IGetBackupFileTypes[] | [];
  allBackupFileType: {id: number; name: string; description: string} | null;
  setAllBackupFileType: (
    fileType: {id: number; name: string; description: string} | null,
  ) => void;
  isAllBackupsShowFilterButtonOpen: boolean;
  setIsAllBackupsShowFilterButtonOpen: (
    prevState: (isOpen: boolean) => boolean,
  ) => void;
  clearAllBackupsFilters: () => void;
  companies: IGetCompaniesListItem[];
  selectedCompanies: IGetCompaniesListItem[];
  setSelectedCompanies: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  selectedBranches: IGetBranchesListItem[];
  setSelectedBranches: (companies: IGetBranchesListItem[]) => void;
}

const AllBackupsTopToolbar: FC<Props> = (props) => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-between',
          marginBottom: '14px',
        }}
      >
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div className={styles.backupsTopToolbarSearch}>
            <input
              name="allBackupsSearch"
              type="text"
              className="input"
              placeholder="Search"
              autoComplete="off"
              value={props.search}
              onChange={(e) => props.setSearch(e.target.value)}
            />
            <img className={styles.searchIcon} src={search} alt="search icon" />
          </div>
          <Button
            variant="text"
            startIcon={
              <FilterIcon
                style={{
                  width: '16px',
                  height: '16px',
                  filter:
                    'invert(25%) sepia(81%) saturate(1632%) hue-rotate(213deg) brightness(95%) contrast(95%)',
                }}
              />
            }
            sx={clearFiltersButton}
            onClick={() => {
              props.setIsAllBackupsShowFilterButtonOpen(
                (prevState) => !prevState,
              );
            }}
          >
            {props.isAllBackupsShowFilterButtonOpen
              ? 'Hide filters'
              : 'Show filters'}
          </Button>
        </Box>
        <div className={styles.topToolbarSelectContainer}>
          {props.metaData && (
            <>
              <span>Show rows:</span>
              <FormControl size="small">
                <Select
                  name="stationsPageCount"
                  value={+props.allBackupsPageSize}
                  onChange={(e) => {
                    props.setAllBackupsPageSize(+e.target.value);
                  }}
                  IconComponent={(props) => <SelectPopupIcon {...props} />}
                  MenuProps={{
                    MenuListProps: {disablePadding: true},
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: selectRowsPaperProps,
                  }}
                >
                  {pageSizes.map((page) => {
                    return (
                      <MenuItem key={page} value={page}>
                        {page}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <span>{`${props?.metaData?.firstItemOnPage}-${props?.metaData?.lastItemOnPage} of ${props?.metaData?.totalItemCount}`}</span>
            </>
          )}
        </div>
      </Box>

      {props.isAllBackupsShowFilterButtonOpen && (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            <DateTimePicker
              inputFormat="DD.MM.YY HH:mm"
              ampm={false}
              minutesStep={1}
              maxDate={props.allBackupsFinishDate}
              value={props.allBackupsStartDate}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                  style: {justifyContent: 'flex-end'},
                },
              }}
              onChange={(newValue) => {
                if (dayjs(newValue).isValid()) {
                  props.setAllBackupsStartDate(dayjs(newValue).utc().format());
                }

                if (newValue === null) {
                  props.setAllBackupsStartDate(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{...calendarStyles, width: '15%'}}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Select start date',
                    sx: {
                      '&::placeholder': {
                        color: '#212121',
                        opacity: '.7',
                      },
                    },
                    autoComplete: 'off',
                  }}
                />
              )}
            />
            <DateTimePicker
              inputFormat="DD.MM.YY HH:mm"
              ampm={false}
              minutesStep={1}
              minDate={props.allBackupsStartDate}
              value={props.allBackupsFinishDate}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                  style: {justifyContent: 'flex-end'},
                },
              }}
              onChange={(newValue) => {
                if (dayjs(newValue).isValid()) {
                  props.setAllBackupsFinishDate(dayjs(newValue).utc().format());
                }

                if (newValue === null) {
                  props.setAllBackupsFinishDate(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{...calendarStyles, width: '15%'}}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Select end date',
                    sx: {
                      '&::placeholder': {
                        color: '#212121',
                        opacity: '.7',
                      },
                    },
                    autoComplete: 'off',
                  }}
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={1}
              options={props.companies || []}
              loading={false}
              size="small"
              popupIcon={<SelectPopupIcon />}
              ListboxComponent={ListboxComponent}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{width: '20%'}}
              value={props.selectedCompanies}
              ListboxProps={{
                style: {maxHeight: '300px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                props.setSelectedBranches([]);

                if (value) {
                  props.setSelectedCompanies(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={option.name}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select company"
                  sx={searchAndSelect}
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={1}
              options={props.branches || []}
              loading={false}
              size="small"
              ListboxComponent={ListboxComponent}
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{width: '20%'}}
              value={props.selectedBranches}
              ListboxProps={{
                style: {maxHeight: '300px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                if (value) {
                  props.setSelectedBranches(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={option.name}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select branch"
                  sx={searchAndSelect}
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={2}
              options={props.stationsForAllBackups || []}
              value={props.selectedStationsForAllBackups || []}
              loading={false}
              size="small"
              ListboxComponent={ListboxComponent}
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{
                width: '40%',
                '.MuiOutlinedInput-notchedOutline': {minHeight: '30px'},
              }}
              disableCloseOnSelect
              ListboxProps={{
                style: {maxHeight: '200px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                if (value) {
                  props.setSelectedStationsForAllBackups(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={option.name}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option?.name}/{option.posChassicNumber}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station/posChassisNumber"
                  sx={searchAndSelect}
                />
              )}
            />
            {/*<Autocomplete*/}
            {/*  options={props.backupsFileTypes || []}*/}
            {/*  loading={false}*/}
            {/*  popupIcon={<SelectPopupIcon />}*/}
            {/*  PaperComponent={(props) => (*/}
            {/*    <Paper*/}
            {/*      sx={{*/}
            {/*        borderRadius: '12px',*/}
            {/*        boxShadow: '0px 4px 20px 0px #4444441F',*/}
            {/*        marginTop: '8px',*/}
            {/*        cursor: 'pointer',*/}
            {/*      }}*/}
            {/*      {...props}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*  sx={{width: '15%'}}*/}
            {/*  value={props.allBackupFileType}*/}
            {/*  ListboxProps={{*/}
            {/*    style: {maxHeight: '200px'},*/}
            {/*    className: 'autoCompleteListBox',*/}
            {/*  }}*/}
            {/*  isOptionEqualToValue={(option, value) =>*/}
            {/*    value.name === '' ? true : option.id === value.id*/}
            {/*  }*/}
            {/*  getOptionLabel={(option) => (option?.name ? option.name : '')}*/}
            {/*  onChange={(_, value) => {*/}
            {/*    if (value) {*/}
            {/*      props.setAllBackupFileType(value);*/}
            {/*    } else {*/}
            {/*      props.setAllBackupFileType(null);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  renderOption={(props: any, option) => (*/}
            {/*    <span {...props} className={styles.dropDownItem}>*/}
            {/*      {option?.name}*/}
            {/*    </span>*/}
            {/*  )}*/}
            {/*  renderInput={(params) => (*/}
            {/*    <TextField*/}
            {/*      {...params}*/}
            {/*      size="small"*/}
            {/*      placeholder="Type"*/}
            {/*      sx={searchAndSelect}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
          </Box>
        </>
      )}
    </div>
  );
};

export default AllBackupsTopToolbar;
