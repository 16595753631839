import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  Divider,
  Modal,
  Paper,
  Snackbar,
  SnackbarOrigin,
  TextField,
} from '@mui/material';
import {CloseIcon, SelectPopupIcon, SuccessNotification} from 'assets/icons';
import {Controller, useForm} from 'react-hook-form';
import React, {FC, useEffect, useState} from 'react';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {chipStyles, searchAndSelect} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';

import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {ErrorMessage} from '@hookform/error-message';
import {IGetBranchesListItem} from 'store/models/branches';
import {modal} from 'styles/MUIStyles/modal';
import styles from './ClientsCreate.module.scss';
import {useCreateClientMutation} from '../../../store/slices/clients';
import {useGetBranchesListQuery} from 'store/slices/branches';

interface Props {
  isCreateClientsModalOpen: boolean;
  setIsCreateClientsModalOpen: (isOpen: boolean) => void;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalInformation: string;
  branches: IGetBranchesListItem[];
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const ClientsCreate: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: {errors},
    control,
  } = useForm<IFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      additionalInformation: '',
      branches: [],
    },
  });
  const {data: branchesList} = useGetBranchesListQuery({});

  const [
    createClient,
    {
      error: createClientErrors,
      isLoading: isCreatingClients,
      isSuccess: isCreatingClientSuccess,
    },
  ] = useCreateClientMutation();

  const [clientEmail, setClientEmail] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {vertical, horizontal, open} = snackbar;

  const onSubmit = async (formData: IFormData) => {
    const response = await createClient({
      ...formData,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
      phoneNumber: formData.phoneNumber.trim(),
      additionalInformation: formData.additionalInformation.trim(),
      branches: formData.branches.map((el) => el.id),
    });

    if (response) {
      if (!('error' in response)) {
        setClientEmail(formData.email);
      }
    }
  };

  useEffect(() => {
    if (isCreatingClientSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      props.setIsCreateClientsModalOpen(false);
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingClientSuccess]);

  useEffect(() => {
    if (createClientErrors) {
      if ('data' in createClientErrors) {
        const errors = createClientErrors.data?.errors;
        if (errors?.email?.[0] || errors?.Email?.[0]) {
          setError('email', {
            type: 'custom',
            message: errors?.email?.[0] || errors?.Email?.[0],
          });
        }

        if (errors?.firstName?.[0] || errors?.FirstName?.[0]) {
          setError('firstName', {
            type: 'custom',
            message: errors?.firstName?.[0] || errors?.FirstName?.[0],
          });
        }

        if (errors?.lastName?.[0] || errors?.LastName?.[0]) {
          setError('lastName', {
            type: 'custom',
            message: errors?.lastName?.[0] || errors?.LastName?.[0],
          });
        }

        if (errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0]) {
          setError('phoneNumber', {
            type: 'custom',
            message: errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0],
          });
        }
        if (errors?.branches?.[0] || errors?.Branches?.[0]) {
          setError('branches', {
            type: 'custom',
            message: errors?.branches?.[0] || errors?.Branches?.[0],
          });
        }
      }
    }
  }, [createClientErrors, setError]);

  const handleSnackbarClose = () => {
    setClientEmail(null);
    setSnackbar({...snackbar, open: false});
  };

  const clearDataAndErrors = () => {
    clearErrors();
    reset();
    props.setIsCreateClientsModalOpen(false);
  };

  return (
    <div>
      {clientEmail ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Password sent to {clientEmail}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={props.isCreateClientsModalOpen}
      >
        <Modal
          open={props.isCreateClientsModalOpen}
          onClose={clearDataAndErrors}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={modal}>
              <div className={styles.clientsCreateModalTop}>
                <h2 className={styles.clientsCreateTitle}>Create user</h2>
                <CloseIcon onClick={clearDataAndErrors} />
              </div>
              <div className={styles.firstNameInputContainer}>
                <label htmlFor="firstName">First Name</label>
                <input
                  {...register('firstName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  className={`${
                    errors?.firstName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter First Name"
                />
                <ErrorMessage
                  name="firstName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.lastNameInputContainer}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  {...register('lastName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  className={`${
                    errors?.lastName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter Last Name"
                />
                <ErrorMessage
                  name="lastName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.emailInputContainer}>
                <label htmlFor="email">Email</label>
                <input
                  {...register('email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  })}
                  type="text"
                  id="email"
                  autoComplete="off"
                  className={`${errors?.email ? 'input inputError' : 'input'}`}
                  placeholder="Enter Email"
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.phoneNumberInputContainer}>
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  {...register('phoneNumber', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="phoneNumber"
                  autoComplete="off"
                  className={`${
                    errors?.phoneNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter phone number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.phoneNumberInputContainer}>
                <label htmlFor="branches">Branches</label>
                <Controller
                  name="branches"
                  control={control}
                  rules={{required: 'This field is required'}}
                  render={({field}) => (
                    <Autocomplete
                      {...field}
                      multiple
                      id="branches"
                      limitTags={3}
                      options={branchesList?.data || []}
                      loading={false}
                      size="small"
                      popupIcon={<SelectPopupIcon />}
                      PaperComponent={(props) => (
                        <Paper
                          sx={{
                            borderRadius: '12px',
                            boxShadow: '0px 4px 20px 0px #4444441F',
                            marginTop: '8px',
                            cursor: 'pointer',
                          }}
                          {...props}
                        />
                      )}
                      ListboxProps={{
                        style: {maxHeight: '300px'},
                        className: 'autoCompleteListBox',
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value.name === '' ? true : option.id === value.id
                      }
                      getOptionLabel={(option) =>
                        option?.name ? option.name : ''
                      }
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({index})}
                            size="small"
                            label={option.name}
                            sx={chipStyles}
                            deleteIcon={
                              <CloseIcon
                                style={{width: '24px', height: '24px'}}
                              />
                            }
                          />
                        ))
                      }
                      renderOption={(props: any, option) => (
                        <span {...props} className={styles.dropDownItem}>
                          {option.name}
                        </span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Branch"
                          sx={searchAndSelect}
                          error={!!errors.branches}
                        />
                      )}
                    />
                  )}
                />
                <ErrorMessage
                  name="branches"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.additionalInfoInputContainer}>
                <label htmlFor="additionalInformation">
                  Additional information - Optional
                </label>
                <input
                  {...register('additionalInformation')}
                  type="text"
                  id="additionalInformation"
                  autoComplete="off"
                  className={`input`}
                  placeholder="Enter additional information"
                />
              </div>
              <Divider
                sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}}
              />
              <div className={styles.buttonsContainer}>
                <Button sx={cancelButton} onClick={clearDataAndErrors}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  type="submit"
                  disabled={isCreatingClients}
                  loading={isCreatingClients}
                  sx={createButtonModal}
                >
                  Create
                </ButtonWithProgress>
              </div>
            </Box>
          </form>
        </Modal>
      </Backdrop>
    </div>
  );
};

export default ClientsCreate;
