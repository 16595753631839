import { Box, List } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Children, forwardRef, useRef, type HTMLAttributes, type ReactElement } from 'react'

export const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, role, ...other } = props

  const items = Children.toArray(children) as ReactElement[]
  const itemCount = items.length

  const scrollRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: itemCount,
    estimateSize: () => 55, //* NOTE: You may have to play around with this value.
    getScrollElement: () => scrollRef.current,
  })

  return (
    <div ref={ref}>
      <List
        {...other}
        role={role}
        ref={scrollRef}
        component="div"
        sx={{
          position: 'relative',
          height: virtualizer.getTotalSize(),
        }}
      >
        {virtualizer.getVirtualItems().map(item => (
          <Box
            key={item.key}
            sx={{
              py: 0.5,
              width: '100%',
              position: 'absolute',
              transform: `translateY(${item.start}px)`,
            }}
          >
            {items[item.index]}
          </Box>
        ))}
      </List>
    </div>
  )
})