import {IStationType, StationsListItem} from 'store/models/stations';
import {Pagination, PaginationItem} from '@mui/material';
import {PaginationArrowLeft, PaginationArrowRight} from 'assets/icons';
import React, {ChangeEvent, FC, useState} from 'react';
import {
  useGetStationTypesListQuery,
  useGetStationsListQuery,
} from 'store/slices/stations';
import {useLocalStorage, useUpdateEffect} from 'hooks';

import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import ManifestTopToolbar from './ManifestTopToolbar/ManifestTopToolbar';
import ManifestsTable from './ManifestsTable/ManifestsTable';
import {pagination} from 'styles/MUIStyles/common';
import styles from './Manifest.module.scss';
import {useGetAllManifestsQuery} from 'store/slices/manifests';
import {useGetBranchesListQuery} from 'store/slices/branches';
import {useGetCompaniesListQuery} from 'store/slices/companies';

const Manifest: FC = () => {
  const [pageSize, setPageSize] = useLocalStorage('manifestsPageSize', 10);
  const [pageNumber, setPageNumber] = useLocalStorage('manifestsPageNumber', 1);

  const [companiesForManifest, setCompaniesForManifest] = useLocalStorage<
    IGetCompaniesListItem[] | null
  >('companiesForManifest', []);
  const [branchesForManifest, setBranchesForManifest] = useLocalStorage<
    IGetBranchesListItem[] | null
  >('branchesForManifest', []); 
  const [stationsForManifest, setStationsForManifest] = useLocalStorage<
    StationsListItem[] | null
  >('stationsForManifest', []);

  const [stationTypes, setStationTypes] = useState<IStationType[]>([]);

  const {data: stationTypesData} = useGetStationTypesListQuery();

  const {data: companies} = useGetCompaniesListQuery({
    branchIds: branchesForManifest,
    stationIds: stationsForManifest,
  });
  const {data: branches} = useGetBranchesListQuery({
    companies: companiesForManifest,
    stationIds: stationsForManifest,
  });
  const {data: stations} = useGetStationsListQuery({
    branches: branchesForManifest,
    companies: companiesForManifest,
  });

  const {data: manifests} = useGetAllManifestsQuery({
    pageSize,
    pageNumber,
    companies: companiesForManifest,
    branches: branchesForManifest,
    stations: stationsForManifest,
    stationTypes: stationTypes,
  });

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  useUpdateEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    }
  }, [pageSize]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.manifestTop}>
        <h1 className={styles.manifestTitle}>Manifests</h1>
      </div>
      <div className={styles.manifestMainContent}>
        <div className={styles.tableFiltersTop}>
          <ManifestTopToolbar
            pageSize={pageSize}
            setPageSize={setPageSize}
            metaData={manifests?.metadata || null}
            companies={companies || []}
            companiesForManifest={companiesForManifest}
            setCompaniesForManifest={setCompaniesForManifest}
            branches={branches?.data || []}
            branchesForManifest={branchesForManifest}
            setBranchesForManifest={setBranchesForManifest}
            stations={stations || []}
            stationsForManifest={stationsForManifest}
            setStationsForManifest={setStationsForManifest}
            stationTypesData={stationTypesData || []}
            stationTypes={stationTypes}
            setStationTypes={setStationTypes}
          />
        </div>
        <div style={{height: '87%', flexGrow: 1}}>
          <ManifestsTable
            manifests={manifests?.data || []}
            metadata={manifests?.metadata || null}
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={manifests?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Manifest;
